import React, { useState } from 'react';
import './FooterFull.scss'
import descargaApp from '../../../assets/images/descarga-app.png';
import appleStore from '../../../assets/images/apple-store.svg';
import playStore from '../../../assets/images/play-store.svg';
import trilogo from '../../../assets/images/trilogo.svg';
import dataweb from '../../../assets/images/dataweb.jpg';
import aaipLogo from '../../../assets/images/aaip-isologo.png';


export const FooterFull = (props) => {


    const [visibleOptions, setVisibleOptions] = useState(true)
    const [rowUp, setRowUp] = useState({ display: "none" })
    const [rowDown, setRowDown] = useState({ display: "block" })


    const handleClick = () => {
        // setVisibleOptions(!visibleOptions);
        setVisibleOptions(prevValue => !prevValue);
    }

    return (
        <div id="footer-full">
            <footer className="footer fusion no-padding hidden-xs">
                <div className="container-fluid footer-white">
                    <div className="container no-padding background-white">
                        <div className="row">
                            <div className="col-sm-10 col-md-8 col-lg-6 no-padding">
                                <div className="col-sm-2 col-md-2 footer-col-border-top media-padding-left-40">
                                        <h4 className="dsk-tahoma-bold footer-title">Televisión</h4>
                                </div>
                                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 footer-col-border-top media-padding-left-40">
                                        <h4 className="dsk-tahoma-bold footer-title">Adicionales</h4>
                                </div>
                                <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 media-padding-left-40">
                                    <h4 className="dsk-tahoma-bold footer-title">Info Útil</h4>
                                    <ul className="no-list-style footer-title-sub-mbl">
                                        <li>
                                            <a href="https://www.cablevision.com.uy/section/view/1866" className="dsk-tahoma-regular item-footer-grey footer-phone">Medios de atención</a>
                                        </li>
                                        <li>
                                            <a href="https://www.cablevision.com.uy/section/view/1874" className="dsk-tahoma-regular item-footer footer-phones">Centros de atención</a>
                                        </li>
                                        <li>
                                            <a href="https://www.cablevision.com.uy/section/view/1864" className="dsk-tahoma-regular footer-phone">Sucursales</a>
                                        </li>
                                        <li>
                                            <a href="https://www.cablevision.com.uy/section/view/1863" className="dsk-tahoma-regular footer-phone">Medios de pago</a>
                                        </li>
                                        <li>
                                            <a href="https://www.cablevision.com.uy/section/view/1862" className="dsk-tahoma-regular footer-phone">Cambio de Domicilio</a>
                                        </li>
                                        <li>
                                            <a href="https://www.cablevision.com.uy/section/view/1870" className="dsk-tahoma-regular footer-phone">Denuncias clandestinas</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 footer-col-border-top media-padding-left-40">
                                    <h4 className="dsk-tahoma-bold footer-title">Sucursal Virtual</h4>
                                    <ul className="no-list-style footer-title-sub-mbl">
                                        <li>
                                            <a href="https://sucursalvirtual.cablevision.com.uy/facturacion" className="dsk-tahoma-regular footer-phone">Ver factura</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2 footer-col-border-top media-padding-left-40">
                                        <h4 className="dsk-tahoma-bold footer-title">Institucional</h4>
                                        <ul className="no-list-style footer-title-sub-mbl">
                                        <li>
                                            <a href="https://www.cablevision.com.uy/seccion-3647-prensa.html" className="dsk-tahoma-regular footer-phone">Prensa</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                <div className="container-fluid disclaimer-cont hidden-xs">
                    <div className="container no-padding">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-7 col-lg-8">
                                <p className="disclaimer no-padding no-margin mob-label hidden-xs">
                                    Los servicios que presta Cablevisión
                                    están sujetos a disponibilidad técnica y
                                    geográfica dentro del territorio de la 
                                    República Oriental del Uruguay<br /><a rel="noreferrer" href="https://www.cablevision.com.uy/section/view/2309" target="_blank" ><span className="color-black">
                                    Protección de datos personales</span></a>
                                    </p>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 no-padding center-social">
                                <div className="col-xs-12 col-md-4 col-lg-4 no-padding center-social">
                                    <i className="fa fa-facebook-square fa-2x chat-icon"></i>   
                                            <a rel="noreferrer" className="text-social padding-icon" href="https://www.facebook.com/CablevisionUy/?fref=ts" title="Cablevision en Facebook" target="_blank">  /cablevisionuy</a>
                                </div>
                                <div className="col-xs-12 col-md-4 col-lg-4 center-social">
                                <i className="fa fa-twitter-square fa-2x chat-icon"></i>   
                                            <a rel="noreferrer" className="text-social padding-icon" href="http://www.twitter.com/cablevisionuy" title="Cablevision en twitter"  target="_blank">  @cablevisionuy</a>
                                </div>
                                <div className="col-xs-12 col-md-4 col-lg-4 no-padding">
                                    <div className="">
                                        <span className="text-social">
                                            Atención al cliente
                                        </span>
                                    </div>
                                    <div className="" >
                                    <i className="fal fa-phone fa-rotate-90 chat-icon phone-icon"></i>   
                                            <span rel="noreferrer" className="text-social font-roboto-bold padding-icon color-customer-social">2619-7000</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer fusion no-padding visible-xs">
                <div className="container-fluid footer-white">
                    <div className="container no-padding background-white">
                        <div className="row">
                            <div className="col-xs-7 col-md-2 col-sm-2 footer-col-border-top media-padding-left-40">
                                    <h4 className="dsk-tahoma-bold footer-title">Adicionales</h4>
                            </div>
                            <div className="col-xs-5 col-md-2 col-sm-2 footer-col-border-top media-padding-left-40">
                                    <h4 className="dsk-tahoma-bold footer-title">Institucional</h4>
                                    <ul className="no-list-style footer-title-sub-mbl">
                                    <li>
                                        <a href="https://www.cablevision.com.uy/seccion-3647-prensa.html" className="dsk-tahoma-regular footer-phone">Prensa</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xs-7 col-md-2 col-sm-2 footer-col-border-top margin-top-20">
                                    <h4 className="dsk-tahoma-bold footer-title">Televisión</h4>
                            </div>
                            
                            <div className="col-xs-7 col-md-2 col-sm-3 media-padding-left-40">
                                <h4 className="dsk-tahoma-bold footer-title">Info Útil</h4>
                                <ul className="no-list-style footer-title-sub-mbl">
                                    <li>
                                        <a href="https://www.cablevision.com.uy/section/view/1866" className="dsk-tahoma-regular footer-phone">Medios de atención</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cablevision.com.uy/section/view/1874" className="dsk-tahoma-regular footer-phones">Centros de atención</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cablevision.com.uy/section/view/1864" className="dsk-tahoma-regular footer-phone">Sucursales</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cablevision.com.uy/section/view/1863" className="dsk-tahoma-regular footer-phone">Medios de pago</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cablevision.com.uy/section/view/1862" className="dsk-tahoma-regular footer-phone">Cambio de Domicilio</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cablevision.com.uy/section/view/1870" className="dsk-tahoma-regular footer-phone">Denuncias clandestinas</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xs-5 col-md-2 col-sm-3 footer-col-border-top media-padding-left-40">
                                <h4 className="dsk-tahoma-bold footer-title">Sucursal Virtual</h4>
                                <ul className="no-list-style footer-title-sub-mbl">
                                    <li>
                                        <a href="https://sucursalvirtual.cablevision.com.uy/facturacion" className="dsk-tahoma-regular footer-phone">Ver factura</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid disclaimer-cont visble-xs">
                    <div className="container no-padding">
                        <div className="row">
                            
                        <div className="line container"></div>
                            <div className="col-xs-12 col-md-4 col-lg-4 no-padding center-social ma-bot-24">
                                <div className="col-xs-12 col-md-4 col-lg-4 no-padding center-social">
                                    <i className="fa fa-facebook-square fa-2x chat-icon"></i>   
                                            <a rel="noreferrer" className="text-social-mobile padding-icon" href="https://www.facebook.com/CablevisionUy/?fref=ts" title="Cablevision en Facebook" target="_blank">  /cablevisionuy</a>
                                </div>
                                <div className="col-xs-12 col-md-4 col-lg-4 no-padding center-social">
                                <i className="fa fa-twitter-square fa-2x chat-icon"></i>   
                                            <a rel="noreferrer" className="text-social-mobile padding-icon" href="http://www.twitter.com/cablevisionuy" title="Cablevision en twitter"  target="_blank">  @cablevisionuy</a>
                                </div>
                                <div className="col-xs-12 col-md-4 col-lg-4 no-padding">
                                    <div className="">
                                        <span className="text-social-mobile">
                                            Atención al cliente
                                        </span>
                                    </div>
                                    <div className="" >
                                    <i className="fal fa-phone fa-rotate-90 chat-icon phone-icon"></i>   
                                            <span rel="noreferrer" className="text-social-mobile font-roboto-bold padding-icon color-customer-social" href="" target="_blank">  2619-7000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="line container"></div>
                            <div className="col-xs-12 col-md-8 col-lg-8 ma-bot-24">
                                <p className="disclaimer no-margin mob-label visible-xs">
                                    Los servicios que presta Cablevisión
                                    están sujetos a disponibilidad técnica y
                                    geográfica dentro del territorio de la 
                                    República Oriental del Uruguay<br/><a rel="noreferrer" href="https://www.cablevision.com.uy/section/view/2309" target="_blank" ><span className="color-black">
                                    Protección de datos personales</span></a>
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default FooterFull;
