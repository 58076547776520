import React from 'react';
import './Information.scss';
import trailerDisney from '../../../assets/images/trailer-1-disney.webp';
import trailerDisneyXS from '../../../assets/images/trailer-1-disney-mobile.png';
import multiDeviceDisney from '../../../assets/images/MultiDeviceDisney.png';
import multiDeviceDisneyXS from '../../../assets/images/MultiDeviceDisneyMob.png';
import downloadDisney from '../../../assets/images/DisneyBajar.png';
import downloadDisneyXS from '../../../assets/images/DisneyBajarMob.png';

export class Information extends React.Component {
    render() {
        return (
            <div className="bg-color-flow-complementario full-width web-content-container no-padding no-margin">
                <div className="js-web-content-desktop no-padding no-margin">
                    <div className="trailer-section-disney">
                        <div className="container no-padding">
                            <div className="container-disney col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="info col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="col-md-offset-2 col-lg-offset-2">
                                        <h3 className="dsk-title-s-bold hidden-xs no-margin text-color__white">Viví la experiencia de Disney+</h3>
                                        <h3 className="dsk-title-bold visible-xs no-margin text-color__white">Viví la experiencia de Disney+</h3>
                                    </div>
                                    <div className="disney-separator col-xs-9 col-sm-9 col-md-12 col-lg-12">
                                    </div>
                                    <div className="description col-xs-offset-1 col-sm-offset-1 col-md-offset-2 col-lg-offset-2 col-xs-10 col-sm-10 no-padding">
                                        <p className="dsk-body text-color__background-gray-100-interfaz">
                                            Con Disney+ podés disfrutar la más completa selección de
                                            películas y series de <span className="dsk-body-rbb">Disney,
                                                Pixar, Marvel, Star Wars y National
                                                Geographic​.​</span> <br/><br/>Además, podés ver una
                                            selección de contenidos en <span className="dsk-body-rbb">4K
                                                UHD y HDR.</span> <br/><br/>
                                            Tus historias favoritas,
                                            nuevos estrenos y los clásicos de siempre en un mismo
                                            lugar. El mejor entretenimiento para todos.</p>
                                    </div>
                                </div>
                                <div className="content col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="image-disney">
                                        {/* <div className="modal-video modal fade" id="videoModalDisney">
                                            <div className="modal-dialog desktop-video-container"> 
                                                <div className="modal-content desktop-video-styles">
                                                    <div className="modal-header video-close"> <button
                                                            type="button" className="close"
                                                            data-dismiss="modal"
                                                            aria-hidden="true">×</button> </div>
                                                    <div className="container"></div>
                                                    <div className="modal-body"> <iframe
                                                            src="./Disfrutá lo mejor de Disney+ en Argentina, exclusivo de Flow_files/eW7Twd85m2g.html"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen=""></iframe> </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        <img src={trailerDisney} className="hidden-xs hidden-sm"/>
                                        <img src={trailerDisneyXS} className="visible-xs visible-sm"/>
                                    </div>
                                </div>
                            </div>
                            <div className="container-disney-2 no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="container-disney-2-box col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="info col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                        <div className="col-md-offset-3  col-lg-offset-3">
                                            <h3 className="dsk-title-s-bold hidden-xs no-margin text-color__white">4 pantallas en simultáneo
                                            </h3>
                                            <h3 className="dsk-title-bold visible-xs no-margin text-color__white">4 pantallas en simultáneo
                                            </h3>
                                        </div>
                                        <div
                                            className="disney-separator col-xs-9 col-sm-9 col-md-12 col-lg-12">
                                        </div>
                                        <div
                                            className="description col-xs-offset-1 col-sm-offset-1 col-md-offset-3 col-lg-offset-3 col-xs-9 col-sm-9 no-padding">
                                            <p
                                                className="dsk-body text-color__background-gray-100-interfaz hidden-xs text-color__white">
                                                Mirá contenidos en forma simultánea hasta 
                                                en <strong>4 pantallas</strong> y disfrutá una experiencia de
                                                visualización personalizada con <strong>7 perfiles</strong>.</p>
                                            <p
                                                className="dsk-body text-color__background-gray-100-interfaz visible-xs text-color__white">
                                                 Mirá contenidos en forma simultánea hasta 
                                                en <strong>4 pantallas</strong> y disfrutá una experiencia de
                                                visualización personalizada con <strong>7 perfiles</strong>. </p>
                                        </div>
                                    </div>
                                    <div className="content col-xs-12 col-sm-12 col-md-4 col-lg-4"> 
                                        <img src={multiDeviceDisney} className="hidden-xs hidden-sm"/> 
                                        <img src={multiDeviceDisneyXS} className="visible-xs visible-sm"/> 
                                    </div>
                                </div>
                                <div className="container-disney-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="content col-md-4 col-lg-4 no-padding hidden-xs hidden-sm">
                                        <img src={downloadDisney} className="hidden-xs hidden-sm"/> </div>
                                    <div className="info col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                        <div className="col-md-offset-0 col-lg-offset-0">
                                            <h3 className="dsk-title-s-bold hidden-xs no-margin text-color__white">Descargá series o películas
                                            </h3>
                                            <h3 className="dsk-title-bold visible-xs no-margin text-color__white">Descargá series o películas
                                            </h3>
                                        </div>
                                        <div
                                            className="disney-separator col-xs-9 col-sm-9 col-md-12 col-lg-12">
                                        </div>
                                        <div
                                            className="description col-xs-offset-1 col-sm-offset-1 col-xs-10 col-md-offset-0 col-lg-offset-0 col-sm-10 col-xs-9 col-md-11 col-lg-10 no-padding">
                                            <p
                                                className="dsk-body text-color__background-gray-100-interfaz hidden-xs text-color__white">
                                                Descargas ilimitadas hasta en <strong>10 dispositivos</strong>. Tus
                                                historias favoritas para ver sin conexión donde y
                                                cuando quieras.</p>
                                            <p
                                                className="dsk-body text-color__background-gray-100-interfaz visible-xs text-color__white">
                                                Descargas ilimitadas hasta en <strong>10 dispositivos</strong>. Tus
                                                historias favoritas para ver sin conexión donde y
                                                cuando quieras.</p>
                                        </div>
                                        <div className="content col-xs-12 col-sm-12 col-md-4 col-lg-4 no-padding visible-xs visible-sm">
                                            <img src={downloadDisneyXS} className="visible-xs visible-sm"/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};