import { Banner } from "./components/banners/Banner1/Banner";
import { Contact } from "./components/banners/Contact/Contact";
import { Devices } from "./components/banners/Devices/Devices";
import { Information } from "./components/banners/Information/Information";
import { Legal } from "./components/banners/Legal/Legal";
import { Login } from "./components/banners/Login/Login";
import { MonoProduct } from "./components/banners/MonoProduct/MonoProduct";
import { Offer } from "./components/banners/Offer/Offer";
import { Questions } from "./components/banners/Questions/Questions";
import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header/Header";

import "./DisneyApp.scss";

function DisneyApp() {
  return (
    <div id="page" className="container">
      <Header />
      <div className="page-row page-row-expanded">
        <div id="main">
          <div className="row">
            <div className="col-sm-12 full-width">
              {/* <Login/> */}
              <Banner/>
              <Contact/>
              <MonoProduct/>
              
              <Devices/>
              <Information/>
              <Questions/>
              <Legal/>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default DisneyApp;
