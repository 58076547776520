import React from "react";
import "./Offer.scss";
import logoDisney from "../../../assets/images/logo_DisneyApp.png";

export class Offer extends React.Component {
  render() {
    return (
      <div id="Offer">
        <div className="no-padding no-margin">
          <div className="js-offer-banner">
            <div className="bannerPrincip" id="widget-combo-disney">
              <div className="container hidden-xs">
                <div className="col-xs-12 col-md-6">
                  <div className="col-xs-12">
                    <div className="banner-title-left">
                      <h2 className="banner-title no-margin text-color__white">
                        ¿No sos cliente?
                        <br /> Comprá Disney+ en combo{" "}
                      </h2>
                      <div className="mt-8">
                        <p className="dsk-title-s mt-24 mb-0">
                          Contratá internet y el mejor
                        </p>
                        <p className="dsk-title-s mb-25">
                          entretenimiento con Flow
                        </p>
                        <p className="mb-25">
                          Además accedé a Disney+ hasta 90 días de regalo.
                        </p>
                      </div>
                      <div className="h-40 mt-16 mb-64">
                        <a
                          href="https://ofertas.cablevisionfibertel.com.ar/internet-cable-y-telefonia"
                          role="button"
                          className="btn btn-primary dsk-body-rbm telecom__button--white__outlined text-color__white h-40 button--rounded-4 disney-banner-combo-button"
                          style={{ width: "187px" }}
                        >
                          CONOCER LAS OFERTAS
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="row mini-banner-right text-color__white">
                    <div className="col-xs-3 col-md-3 icon-family no-padding text-center js-product-icons pos-relative">
                      <div className="col-xs-9 no-padding js-product-icons-container">
                        <span className="banner-ico fal fa-tv"></span>
                        <br />
                        <p className="banner-icon-text">Flow</p>
                      </div>
                      <div className="col-xs-3 no-padding js-product-icons-separator">
                        <span className="plus">+</span>
                      </div>
                    </div>

                    <div className="col-xs-3 col-md-3 icon-family no-padding text-center js-product-icons pos-relative">
                      <div className="col-xs-9 no-padding js-product-icons-container">
                        <span className="banner-ico fal fa-wifi"></span>
                        <br />
                        <p className="banner-icon-text">Internet</p>
                      </div>
                      <div className="col-xs-3 no-padding js-product-icons-separator">
                        <span className="plus">+</span>
                      </div>
                    </div>

                    <div className="col-xs-3 col-md-3 icon-family no-padding text-center js-product-icons pos-relative">
                      <div className="col-xs-9 no-padding js-product-icons-container">
                        <img className="logo-disney-app" src={logoDisney} />
                        <br />
                        <p className="banner-icon-text">Disney +</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row visible-xs">
                <div className="col-xs-12 col-md-6">
                  <div className="continer-fluid">
                    <div className="banner-title-left">
                      <h2 className="dsk-title-bold-m no-margin lh-40 color-inherit">
                        ¿No sos cliente?
                        <br />
                        En combo te conviene mucho más.
                      </h2>
                      <div className="mt-17 ml-37 mr-37">
                        <p className="dsk-body-m lh-22">
                          Contratá internet y el mejor
                          <br />
                          entretenimiento con Flow
                        </p>
                      </div>
                      <p className="dsk-body-s lh-22">
                        Además accedé a Disney+ hasta 90 días de regalo.
                      </p>
                      <div className="row mt-21">
                        <div className="icon-family no-padding text-center js-product-icons col-xs-3 col-xs-offset-2 pos-relative">
                          <div className="no-padding js-product-icons-container col-xs-9">
                            <span className="banner-ico fal fa-tv"></span>
                            <br />
                            <p className="banner-icon-text">Flow</p>
                          </div>
                          <div className="no-padding js-product-icons-separator">
                            <span className="plus">+</span>
                          </div>
                        </div>
                        <div className="icon-family no-padding text-center js-product-icons col-xs-3 pos-relative">
                          <div className="no-padding js-product-icons-container col-xs-9">
                            <span className="banner-ico fal fa-wifi"></span>
                            <br />
                            <p className="banner-icon-text">Internet</p>
                          </div>
                          <div className="no-padding js-product-icons-separator">
                            <span className="plus">+</span>
                          </div>
                        </div>
                        <div className="icon-family no-padding text-center js-product-icons col-xs-3 pos-relative">
                          <div className="no-padding js-product-icons-container col-xs-9">
                            <img className="logo-disney-app" src={logoDisney} />
                            <br />
                            <p className="banner-icon-text">Disney +</p>
                          </div>
                        </div>
                      </div>
                      <div className="h-40 mt-27">
                        <a
                          href="https://ofertas.cablevisionfibertel.com.ar/internet-cable-y-telefonia"
                          role="button"
                          style={{ paddingTop: "10px" }}
                          className="btn btn-primary button--rounded-4 dsk-body-rbm telecom__button--white__outlined text-color__white h-40 btn-block disney-banner-combo-button"
                        >
                          CONOCER LAS OFERTAS
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
