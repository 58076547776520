
import React from 'react';
import './Contact.scss';

export class Contact extends React.Component {
    render() {
        return (
            <div className="phone-chat-section container-fluid">
                <i className=" fal fa-phone fa-rotate-90 phone-icon-mobile text-color__white"></i>
                <div className="phone-text">
                    <span className="dsk-body">
                        <span className="dsk-body hidden-xs text-color__white">Venta telefónica</span>
                        <a className="dsk-body visible-xs text-color__white" href="tel:26197000">Venta teléfonica <strong>2619-7000</strong></a>
                    </span>
                    <span className="dsk-body font-bold hidden-xs phone-number text-color__white"> 2619-7000 </span>
                </div>
                {/* <span className="dsk-title pipe"> | </span>
                <div className="chat-text">
                    <i className="fal fa-comment-lines chat-icon text-color__white"></i> 
                    <span className="dsk-body font-bold chat-link">             
                        <a rel="noreferrer" className="chat-link text-color__white" href="https://srvchat-genesys.cablevisionfibertel.com.ar:8443/WebChatMobile/?URLCOMERCIAL=WEB_OCC_Ofertas&amp;sala=VENTAS_CHAT#chat" target="_blank">Chat Online</a>
                    </span>
                </div> */}
            </div>
        )
    }
}