
import React from 'react';
import './MonoProduct.scss';
import logo from '../../../assets/images/DisneyPlus_EmotionalBanner.png';
import logoXS from '../../../assets/images/DisneyPlus_EmotionalBannerMob.png';

export class MonoProduct extends React.Component {

    onClick(){
        window.location.href= process.env.REACT_APP_API_URL;
    }

    render() {
        return (
            <div className="mono-product-banner grey">
                <div id="OCS_DisneyEmotionalContent" className="row">
                    <div className="banner text-center p-0 grey" >
                        <img className="img-banner hidden-xs" src={logo} alt="Disney+ en Flow y Fibertel"/>
                        <img className="img-banner visible-xs" src={logoXS} style={{marginBottom: "16px"}} alt="Disney+ en Flow y Fibertel"/> 
                    </div>
                    <div className="content w-100 grey">
                        <div className="title">
                            <h2 className="dsk-title-black-l text-center white hidden-xs">Conocé Disney+ con Cablevisión</h2>
                            <p className="dsk-body-m text-center white hidden-xs">Disfrutá de Disney, Pixar, Marvel, Star Wars y National Geographic.</p>
                            
                            <h2 className="dsk-title-black-sm text-center white visible-xs mb-0">Conocé Disney+ </h2>
                            <h2 className="dsk-title-black-sm text-center white visible-xs mt-0">con Cablevisión</h2>
                         
                            <span className="dsk-body-m text-center white margin-top-15-xs visible-xs">Disfrutá de Disney, Pixar, Marvel,</span>
                            <span className="dsk-body-m text-center white visible-xs">Star Wars y National Geographic.</span>
                        </div>
                        <div className="banner-footer" style={{marginTop: "30px"}}>
                            <div className="col-xs-12 text-center grey">
                                <button className="login-saml btn btn-disney banner-button-disney h-40 telecom__button--dodger-blue__filled"
                                        onClick={this.onClick}>
                                    ACTIVAR DISNEY+
                                </button>
                            </div>      
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}