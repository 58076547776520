
import React from 'react';
import './Questions.scss'

export class Questions extends React.Component {
    render() {
        return (
            <div className="pos-relative">
                <div className="full-width web-content-container no-padding no-margin">
                    <div className="js-web-content-desktop no-padding no-margin">
                        <div id="faq_div">
                            <section className="container section-questions">
                                <div className="row">
                                    <div className="col-md-12 mb-45 p-0">
                                        <div className="dsk-title-m font-bold">
                                            <h2 className="dsk-title-bold-m m-0">
                                                <span key="module_faq-title">
                                                    Preguntas frecuentes
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div id="accordion">
                                            <div className="question question-custom question-border-bottom">
                                                <div className="question-header question-header-custom">
                                                    <div className="question-header-title-container">
                                                        <i className="fal fa fa-angle-down question-icon">‌</i>
                                                        <h3 className="question-header-title btn-arrows accordeon bt-faq-toogle dsk-body-m"
                                                            href="#collapseOne"
                                                            data-toggle="collapse">
                                                            ¿Qué es Disney+?
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div
                                                    aria-labelledby="headingOne"
                                                    className="collapse"
                                                    data-parent="#accordion"
                                                    id="collapseOne"
                                                    styles="display: none;">
                                                    <div className="question-body p-15">
                                                        <div className="info dsk-body question-body-custom">
                                                            <p>Disney+ es una plataforma de streaming, 
                                                                en donde podrás acceder a la completa selección de películas y series de Disney,
                                                                 Pixar, Marvel, Star Wars y National Geographic.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="question question-custom question-border-bottom"
                                                data-faq-element-name="¿Cuáles son las características y beneficios de Disney+?">
                                                <div className="question-header question-header-custom">
                                                    <div className="question-header-title-container">
                                                        <i
                                                            className="fal fa fa-angle-down question-icon">‌</i>
                                                        <h3 className="question-header-title btn-arrows accordeon bt-faq-toogle dsk-body-m"
                                                            href="#collapseTwo"
                                                            data-toggle="collapse">
                                                            ¿Cuáles son las características y beneficios
                                                            de Disney+?
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div aria-labelledby="headingTwo"
                                                    className="collapse"
                                                    data-parent="#accordion"
                                                    id="collapseTwo"
                                                    styles="display: none;">
                                                    <div className="question-body p-15">
                                                        <div className="info dsk-body question-body-custom">
                                                            <p>Con Disney+ podés disfrutar de la más completa selección de películas y series de Disney, 
                                                                Pixar, Marvel, Star Wars y National Geographic. 
                                                                Además, podés ver de forma simultánea contenidos diferentes en 4 dispositivos y 
                                                                disfrutar de una experiencia de visualización personalizada con hasta 7 perfiles. 
                                                                Desde cualquier dispositivo y con una selección de entretenimiento para todos en 4k. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="question question-custom question-border-bottom"
                                                data-faq-element-name="¿Cómo contrato Disney+?">
                                                <div className="question-header question-header-custom">
                                                    <div className="question-header-title-container">
                                                        <i className="fal fa fa-angle-down question-icon">‌</i>
                                                        <h3 className="question-header-title btn-arrows accordeon bt-faq-toogle dsk-body-m"
                                                            href="#collapseThree"
                                                            data-toggle="collapse">
                                                            ¿Cómo contrato Disney+?</h3>
                                                    </div>
                                                </div>
                                                <div aria-labelledby="headingOne"
                                                    className="collapse"
                                                    data-parent="#accordion"
                                                    id="collapseThree"
                                                    styles="display: none;">
                                                    <div className="question-body p-15">
                                                        <div className="info dsk-body question-body-custom">
                                                            <p>Para contratar Disney+, 
                                                            podés activarlo ahora con tu suscripción de Flow o comunicate al 26197000 y 
                                                            disfrutá los primeros días de regalo.</p>
                                                            {/* <p>Para&nbsp;contratar Disney+, podés <a
                                                                href="https://web.flow.com.ar/dispneyplus%E2%80%8B">activarlo&nbsp;ahora </a>
                                                                o comunicate al 0800-199-6835 y disfrutá
                                                                los primeros días de regalo.</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="question question-custom question-border-bottom"
                                                data-faq-element-name="¿Tengo que tener Flow para contratar Disney+?">
                                                <div className="question-header question-header-custom">
                                                    <div className="question-header-title-container">
                                                        <i className="fal fa fa-angle-down question-icon">‌</i>
                                                        <h3 className="question-header-title btn-arrows accordeon bt-faq-toogle dsk-body-m"
                                                            href="#collapseFour"
                                                            data-toggle="collapse">
                                                            ¿Tengo que tener Flow para contratar Disney+?</h3>
                                                    </div>
                                                </div>
                                                <div aria-labelledby="headingOne"
                                                    className="collapse"
                                                    data-parent="#accordion"
                                                    id="collapseFour"
                                                    styles="display: none;">
                                                    <div className="question-body p-15">
                                                        <div className="info dsk-body question-body-custom">
                                                            <p>Si tenés Flow podés acceder a la contratación de Disney+ 
                                                                y tener una mejor experiencia de entretenimientos</p>
                                                            {/* <p>Si tenés Flow podés acceder a
                                                            la contratación
                                                                de <a href="https://web.flow.com.ar/dispneyplus">
                                                                    Disney+
                                                                </a> y tener una mejor experiencia de
                                                                entretenimientos. También, podés acceder
                                                                a Disney Plus si tenés Personal o
                                                                Fibertel.</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="question question-custom sb"
                                                data-faq-element-name="¿Cómo puedo ver Disney+ en Argentina?"
                                                styles="border: none; box-shadow: none; border-radius: 0; padding: 0; margin-bottom: 0;">
                                                <div className="question-header question-header-custom">
                                                    <div className="question-header-title-container">
                                                        <i className="fal fa fa-angle-down question-icon">‌</i>
                                                        <h3 className="question-header-title btn-arrows accordeon bt-faq-toogle dsk-body-m"
                                                            href="#collapseFive"
                                                            data-toggle="collapse">
                                                            ¿Cómo puedo ver Disney+ en Uruguay?
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div aria-labelledby="headingOne"
                                                    className="collapse"
                                                    data-parent="#accordion"
                                                    id="collapseFive"
                                                    styles="display: none;">
                                                    <div className="question-body p-15">
                                                        <div className="info dsk-body question-body-custom">
                                                            <p>Para ver toda la programación de Disney Plus en Uruguay, 
                                                                lo podés contratar a través de Flow. 
                                                                Si ya sos cliente solamente tenés que suscribirte.</p>
                                                            {/* <p>Para ver toda la programación de Disney
                                                            Plus en Argentina, lo
                                                                podés&nbsp;contratar a través de <a
                                                                    href="https://web.flow.com.ar/dispneyplus">Flow
                                                                    o Personal</a>. Si ya sos cliente
                                                                solamente tenés que suscribirte.</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}