import React from "react";
import './Footer.scss'
import { PreFooter } from "./PreFooter/PreFooter";
import { FooterFull } from "./FooterFull/FooterFull";

export class Footer extends React.Component {
  render() {
    return (
        <footer id="footerBar" className="page-row">
            <div className="row">
                <div className="redBox">
                    <div className="col-sm-12 full-width">
                        {/* <PreFooter/> */}
                        <FooterFull/>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}
