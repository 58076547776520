import React from 'react';
import './Devices.scss';
import logoFlow from '../../../assets/images/flow_cablevision_uy.svg';

export class Devices extends React.Component {
    render() {
        return (
            <div className="bg-color-flow-complementario full-width web-content-container no-padding no-margin">
                <div className="js-web-content-desktop no-padding no-margin">
                    <div className="section-product-detail-disney text-color__white text-center">
                        <div className="container no-padding">
                            <h2 className="title dsk-title-bold-m no-margin hidden-xs">Mirá Disney+ en todos tus dispositivos favoritos</h2> 
                            <span className="dsk-body hidden-xs">Disfrutá el mejor contenido exclusivo de Disney+ donde quieras</span>

                            <h2 className="title dsk-title-bold-m no-margin visible-xs mb-10">Mirá Disney+ en todos tus dispositivos favoritos</h2> 
                            <span className="dsk-body visible-xs">Podrás ver todos los contenidos de la experiencia </span>
                            <span className="dsk-body visible-xs">Disney en múltiples dispositivos</span>
                            <div className="product-details col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
                                <div className="box col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="icon">
                                        <img src={logoFlow} alt="Logo Flow"/>
                                    </div>
                                    <p className="dsk-body-m">Flow</p>
                                </div>
                                <div className="box col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="icon"> <i className="fal fa-tablet"></i> </div>
                                    <p className="dsk-body-m">Tablet</p>
                                </div>
                                <div className="box col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="icon"> <i className="fal fa-mobile"></i> </div>
                                    <p className="dsk-body-m">Celular</p>
                                </div>
                                <div className="box col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="icon"> <i className="fal fa-browser"></i> </div>
                                    <p className="dsk-body-m">PC</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};