import React from 'react';
import './Login.scss';

export class Login extends React.Component {
    render() {
        return (
            <div id="login">
                <div className="loginTape">
                    <div className="contentLogin container">
                        <div className="s-text"> <span className="s-title"><b>¿Sos cliente?</b></span> <span
                                className="s-login"><a id="login-saml" target="">Iniciá
                                    sesión</a></span> <span className="s-descr hidden-xs">para ver
                                ofertas exclusivas</span> <span className="s-descr visible-xs">para ver
                                ofertas exclusivas </span></div>
                        <div className="s-bottom"> <button className="s-close" id="closeTape">×</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}                                