import React, { useState } from "react";
import "./Header.scss";
import { Menu } from "./Menu/Menu";
import { Navbar } from "./Navbar/Navbar";


export const Header = (props) => {

  const [visibleMenuMovile, setVisibleMenuMovile] = useState(false)

  const handleClickMenuMovile = () => {
    // setVisibleOptions(!visibleOptions);
    setVisibleMenuMovile(prevValue => !prevValue);
}

    return (
      <header id="headerBar" className="page-row hidden-lg hidden-xl hidden-md hidden-sm">
        <div className="row">
          <div className="col-sm-12 full-width">
            <div id="header">
              <div id="menu-shade" styles="opacity: 1; display: none;"></div>
              <nav className="navbar navbar-default navbar-fixed-top neotabs fusion not-logged"
                role="navigation">
                <Navbar handleClickMenuMovile={handleClickMenuMovile} visibleMenuMovile={visibleMenuMovile}/>
                <Menu visibleMenuMovile={visibleMenuMovile}/>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
}


export default Header;
