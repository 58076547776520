import React from 'react';
import './Banner.scss';
import trilogo from '../../../assets/images/Logo_Cable.png';

export class Banner extends React.Component {

  onClick() {
    window.location.href = process.env.REACT_APP_API_URL;
  }

  render() {
    return (
      <div id="nav-banner" className="mono-product-banner" style={{ backgroundColor: "#0E3376" }}>

        <div className="banner-sm hidden-xs">
          <div className="row row_navbar">
            <div className="img-banner">

              <div className="nav_bar_1">

              </div>
              <div >
                <nav className="nav_bar_2">
                  <div>
                    <img src={trilogo} className="logo_Uy nav navbar-nav nav-nivel1 menu-nivel1" />
                  </div>

                  <div className="navbar-items">
                    <ul className="nav navbar-nav nav-nivel1 menu-nivel1" >
                      <li className="nohover">
                        <a id=""
                          className="dsk-lato-bold-18-333"
                          href="https://www.cablevision.com.uy/"
                          aria-label="Televisión"
                          target="_blank">Televisión</a>
                      </li>
                      <li className="nohover">
                        <a id="ofertas-btn"
                          className="dsk-lato-bold-18-333"
                          href="https://www.cablevision.com.uy/ "
                          aria-label="Info Útil"
                          target="_blank">Info Útil</a>
                      </li>
                      <li className="nohover">
                        <a id="ofertas-btn"
                          className="dsk-lato-bold-18-333"
                          href="https://sucursalvirtual.cablevision.com.uy/#!/login"
                          aria-label="Sucursal Virtual"
                          target="_blank">Sucursal Virtual</a>
                      </li>   <li className="nohover2">
                        <a id="ofertas-btn"
                          className="dsk-lato-bold-18-333"
                          href="https://www.cablevision.com.uy/buscador"
                          aria-label="ProgramaciónS"
                          target="_blank">Programación</a>
                      </li>
                    </ul>
                  </div>
                </nav>

              </div>
            </div>

          </div>

          <div className="row text-color__white">

            <div className="container">
              <div className="col-sm-6 col-xs-12 padding-t-15">
                <div className="col-lg-9 col-md-12 mb-45">
                  <span className="dsk-body-rbr text-color__white">
                    OFERTA SOLO PARA CLIENTES
                        </span>
                  <h1 className="dsk-title-l text-color__white lh-117 mt-0 mb-10">
                    Disfrutá Disney+
                        </h1>
                  <span className="dsk-title-s text-color__white lh-133" data-banner-category="Ahorrá y conectate con todo">
                    $320 por mes.
                  </span>
                </div>
                <div className="col-lg-7 col-md-9 text-center">
                  <button className="login-saml btn btn-primary telecom__button--dodger-blue__filled h-40 button--rounded-4  dsk-body-rbm button_Uy"
                    onClick={this.onClick}>
                    ACTIVAR
                        </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-xs visible-xs">

          <div className="row text-color__white">
            <div className="col-xs-12 col-md-offset-1 col-md-5">
              <div className="col-sm-6 col-xs-12">
                <div className="col-xs-12 no-padding">
                  <p className="dsk-body-rbr text-color__white">OFERTA SOLO PARA CLIENTES</p>
                  <h1 className="dsk-title-black-m text-color__white mt-0 lh-125 mb-10">Disfrutá Disney+</h1>
                  <span className="dsk-subtitle-s-uy text-color__white lh-133" data-banner-category="Ahorrá y conectate con todo">
                    $320 por mes.
                  </span>
                </div>
                <div className="col-xs-12 no-padding text-center pt-64 margin-bottom-58">
                  <button className="login-saml col-xs-12 btn btn-primary telecom__button--dodger-blue__filled h-40 button--rounded-4 banner-button-disney dsk-body-rbm button_Uy"
                    onClick={this.onClick}>
                    ACTIVAR
                        </button>
                  {/* <div className="col-xs-12 dsk-body mt-16 mb-22">
                          <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#widget-combo-disney" className="text-center text-color__white">No soy cliente</a>
                        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};