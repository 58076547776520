import React from 'react';
import './Menu.scss';


export const Menu = ({ visibleMenuMovile }) => {


    return (
        <div>
            <div className="container menu-cont second-nav">
                <div id="navbar" className={`navbar-collapse collapse navbar-left submenu ${visibleMenuMovile ? "menuopen" : ""}`}>
                    <div className="side-menu"></div>
                    <ul className="nav navbar-nav nav-nivel1 menu-nivel1">
                        <li>
                            <a id=""
                                className="text_nav_mobile b-button"
                                href="https://www.cablevision.com.uy/"
                                aria-label="Televisión"
                                target="_blank"
                                rel="noreferrer">Televisión</a>
                        </li>

                        <li>
                            <a id="ofertas-btn"
                                className="text_nav_mobile b-button"
                                href="https://www.cablevision.com.uy/ "
                                aria-label="Info Útil"
                                target="_blank"
                                rel="noreferrer">Info Útil</a>

                        </li>
                        <li>
                            <a id="ofertas-btn"
                                className="text_nav_mobile b-button"
                                href="https://sucursalvirtual.cablevision.com.uy/#!/login"
                                aria-label="Sucursal Virtual"
                                target="_blank"
                                rel="noreferrer">Sucursal Virtual</a>
                        </li>
                        <li>
                            <a id="ofertas-btn"
                                className="text_nav_mobile"
                                href="https://www.cablevision.com.uy/buscador"
                                aria-label="ProgramaciónS"
                                target="_blank"
                                rel="noreferrer">Programación</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};


export default Menu;