import React, { useState } from 'react';
import './Navbar.scss';
import trilogo from '../../../assets/images/Logo_Cable.png';


export const Navbar = ({handleClickMenuMovile, visibleMenuMovile}) => {

    const [visibleOptions, setVisibleOptions] = useState(false)

    const handleClick = () => {
        // setVisibleOptions(!visibleOptions);
        setVisibleOptions(prevValue => !prevValue);
    }


    return (
        <nav className="menu-top logged c-baja">
            <div className="container men no-padding">
                <div className="row pos-relative">
                    {/* <div className="cd-logo hidden-xs display-block" style={{height: "70px"}}>
                        <a href="https://www.cablevisionfibertel.com.ar/" rel="noreferrer">
                            <img src={trilogo} className="logo" style={{height: "64px"}}/>
                        </a>
                    </div> */}
                    {/* <div className="navbar-header hidden-xs">
                        <div className="dropdown estasbtn dsk-body">
                            <button className="unbutton dropdown-toggle outline" 
                                id="selectorSitio" 
                                tabIndex="1"
                                onClick={handleClick}>
                                Estás en: <span>Cablevisión Fibertel
                                    <i className="imagedropdw fa fa-angle-down"></i></span>
                            </button>
                            <ul id="collapseProducts" className={`dropdown-menu-estasen ${visibleOptions ? "open" : ""}`}>
                                <li>
                                    <a id="fst" tabIndex="1" className="active">Cablevisión Fibertel</a>
                                </li>
                                <li>
                                    <a href="https://www.personal.com.ar/" target="_blank" rel="noreferrer" tabIndex="1">Personal</a>
                                </li>
                                <li>
                                    <a href="https://www.arnet.com.ar/" target="_blank" rel="noreferrer" tabIndex="1">Arnet</a>
                                </li>
                                <li>
                                    <a href="https://hogares.telecom.com.ar/" target="_blank" rel="noreferrer" tabIndex="1">Telecom</a>
                                </li>
                                <li>
                                    <a href="https://www.telecomfibercorp.com.ar/" target="_blank" rel="noreferrer" tabIndex="1">Telecom FiberCorp</a>
                                </li>
                                <li>
                                    <a href="https://www.telecomfibercorp.com.ar/pymes/productos/comunicaciones/planes-moviles" target="_blank" rel="noreferrer" tabIndex="1" >Personal Empresas</a>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="visible-xs cd-logo" style={{height: "65px"}}>
                        <a href="https://www.cablevisionfibertel.com.ar/" rel="noreferrer">
                            <img src={trilogo} className="logo" style={{height: "50px"}}/>
                        </a>
                    </div> */}
                    <div className="navbar-header visible-xs">
                    <a href="https://www.cablevision.com.uy/" rel="noreferrer">
                            <img src={trilogo} className="logo" style={{height: "50px"}}/>
                        </a>

                        {/* <div className="dropdown estasbtn dsk-body">
                            <button className="unbutton dropdown-toggle">
                                Estás en: <span>Cablevisión Fibertel
                                    <i className="imagedropdw fa fa-angle-down"></i></span>
                            </button>
                            <ul className="dropdown-menu-estasen">
                                <li>
                                    <a>Cablevisión Fibertel</a>
                                </li>
                                <li>
                                    <a href="https://www.personal.com.ar/" target="_blank" rel="noreferrer">Personal</a>
                                </li>
                                <li>
                                    <a href="https://www.arnet.com.ar/" target="_blank" rel="noreferrer">Arnet</a>
                                </li>
                                <li>
                                    <a href="https://hogares.telecom.com.ar/" target="_blank" rel="noreferrer">Telecom</a>
                                </li>
                                <li>
                                    <a href="https://www.telecomfibercorp.com.ar/" target="_blank" rel="noreferrer">Telecom FiberCorp</a>
                                </li>
                                <li>
                                    <a href="https://www.telecomfibercorp.com.ar/pymes/productos/comunicaciones/planes-moviles"
                                        target="_blank"
                                        rel="noreferrer">Personal Empresas</a>
                                </li>
                            </ul>
                        </div> */}
                        <div id="nav-icon" 
                            className={`visible-xs menu-bar-mobile ${visibleMenuMovile ? "open" : ""}`} 
                            onClick={handleClickMenuMovile}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
};


export default Navbar;