import React from 'react';
import './Legal.scss';
import moment from 'moment';
import 'moment/locale/es';

export const Legal = () => {
  moment.locale('es');
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const initDate = moment([year, month]);
  const initDateFormated = moment([year, month]).format('DD/MM/YYYY');
  const endDateFormated = moment(initDate).endOf('month').format('DD/MM/YYYY');
  const nameMonth = initDate.format('MMMM').toUpperCase();

  return (
    <div>
      <div className='col-xs-12 background-form no-padding border-top-solid m-35'>
        <div className='container content-legales'>
          <p
            href='#collapseOne_OCS_LegalText_v1-wi1200020'
            data-toggle='collapse'
          >
            {' '}
            Legales <i className='fa fa-angle-down'></i>{' '}
          </p>
          <div
            id='collapseOne_OCS_LegalText_v1-wi1200020'
            className='collapse dsk-txt-sm context-box-legales'
          >
            <p>DISNEY+</p>
            <p>
              SERVICIO SUJETO A DISPONIBILIDAD TÉCNICA Y GEOGRÁFICA. OFERTA
              VÁLIDA DESDE EL {initDateFormated} HASTA EL {endDateFormated}PARA
              CLIENTES DE CABLEVISIÓN CON SERVICIOS ACTIVOS DE TELEVISIÓN
              BRINDADO POR CABLEVISION BAJO CUALQUIER MODALIDAD O PLATAFORMA.
              A ENERO DE 2022 EL PRECIO DE LISTA ASCIENDE A $320 IVA INCLUIDO
              POR MES. DISNEY+ SERÁ BRINDADO POR DISNEY DTC LATAM, INC.,
              MEDIANTE UNA APLICACIÓN, SOFTWARE Y PLATAFORMA TECNOLÓGICA DE SU
              PROPIEDAD, DE CONFORMIDAD CON SUS RESPECTIVOS TÉRMINOS Y
              CONDICIONES DE USO PUBLICADOS EN WWW.DISNEYPLUS.COM QUE EL CLIENTE
              DECLARA CONOCER Y ACEPTAR EXPRESAMENTE AL MOMENTO DE SU
              CONTRATACIÓN DEL SERVICIO. DISNEY+ SERÁ FACTURADO POR CABLEVISIÓN.
              LA EFECTIVA UTILIZACIÓN DEL SERVICIO POR PARTE DEL CLIENTE
              REQUIERE LA FINALIZACIÓN DE SU ACTIVACIÓN. DISNEY DTC LATAM, INC.,
              2400 WEST ALAMEDA AVENUE, BURBANK CA91521, ESTADOS UNIDOS DE
              AMÉRICA.WWW.DISNEYPLUS.COM. PARA MÁS INFORMACIÓN CONSULTE
              EN WWW.CABLEVISION.COM.UY O POR EL 26197000.
            </p>
          </div>
        </div>
      </div>

      <div
        className='modal modal-mobile fade hide'
        id='myModal'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-hidden='true'
              >
                <i className='fal fa-times'></i>
              </button>
            </div>
            <div className='modal-body text-legal'>
              <p data-bind='text: legalesText' className='text-legal'>
                <span className='text-legal'>DISNEY+ </span>
                SERVICIO SUJETO A DISPONIBILIDAD TÉCNICA Y GEOGRÁFICA. OFERTA
                VÁLIDA DESDE EL {initDateFormated} HASTA EL {endDateFormated}
                PARA CLIENTES DE CABLEVISIÓN CON SERVICIOS ACTIVOS DE TELEVISIÓN
                BRINDADO POR CABLEVISION BAJO CUALQUIER MODALIDAD O PLATAFORMA.
                A ENERO DE 2022 EL PRECIO DE LISTA ASCIENDE A $320 IVA INCLUIDO
                POR MES. DISNEY+ SERÁ BRINDADO POR DISNEY DTC LATAM, INC.,
                MEDIANTE UNA APLICACIÓN, SOFTWARE Y PLATAFORMA TECNOLÓGICA DE SU
                PROPIEDAD, DE CONFORMIDAD CON SUS RESPECTIVOS TÉRMINOS Y
                CONDICIONES DE USO PUBLICADOS EN WWW.DISNEYPLUS.COM QUE EL
                CLIENTE DECLARA CONOCER Y ACEPTAR EXPRESAMENTE AL MOMENTO DE SU
                CONTRATACIÓN DEL SERVICIO. DISNEY+ SERÁ FACTURADO POR
                CABLEVISIÓN. LA EFECTIVA UTILIZACIÓN DEL SERVICIO POR PARTE DEL
                CLIENTE REQUIERE LA FINALIZACIÓN DE SU ACTIVACIÓN. DISNEY DTC
                LATAM, INC., 2400 WEST ALAMEDA AVENUE, BURBANK CA91521, ESTADOS
                UNIDOS DE AMÉRICA.WWW.DISNEYPLUS.COM. PARA MÁS INFORMACIÓN
                CONSULTE EN WWW.CABLEVISION.COM.UY O POR EL 26197000.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Legal;
