import React from "react";
import './PreFooter.scss'

export class PreFooter extends React.Component {
  render() {
    return (
            <div id="pre-footer">
                <div className="row prefooter-row">
                    <div className="container no-padding" role="contentinfo">
                        <div className="row prefooter-data-row hidden-xs">
                            <div className="col-centered w-30">
                                <div className="prefooter-title-box">
                                    <span className="prefooter-title dsk-body-m-rbm">Combos
                                            TV + Internet + Telefonía Móvil</span>
                                    
                                </div>
                                <div className="prefooter-detail">
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Móvil
                                        3 GB + Internet 50 MB + Flow Box</a>
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Móvil
                                        3 GB + Internet 50 MB + TV Clásico</a>
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Móvil
                                        5 GB + Internet 50 MB + Flow Box</a>
                                </div>
                            </div>
                            <div className="col-centered w-15">
                                <div className="prefooter-title-box">
                                    <a 
                                        href="https://ccstore-stage-zepa.oracleoutsourcing.com/tv" _if="/tv">
                                        <h3 className="prefooter-title dsk-body-m-rbm no-margin no-padding"
                                            >Televisión</h3>
                                    </a>
                                </div>
                                <div className="prefooter-detail">
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Clásico</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Flow</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Flow
                                        Now</a>
                                </div>
                            </div>
                            <div className="col-centered w-15">
                                <div className="prefooter-title-box">
                                    
                                        <h3 className="prefooter-title dsk-body-m-rbm no-margin no-padding"
                                            >Adicionales</h3>
                                   
                                </div>
                                <div className="prefooter-detail">
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Pack
                                        Fútbol</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Pack
                                        HBO Max</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Pack
                                        FOX Premium</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Pack
                                        HOT</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Disney+</a>

                                </div>
                            </div>
                            <div className="col-centered w-12">
                                <div className="prefooter-title-box">
                                    <a 
                                        href="https://ccstore-stage-zepa.oracleoutsourcing.com/internet"
                                        _if="/internet">
                                        <h3 className="prefooter-title dsk-body-m-rbm no-margin no-padding"
                                            >Internet</h3>
                                    </a>
                                </div>
                                <div className="prefooter-detail">
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Fibertel
                                        Zone</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">1.000
                                        MB</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">300
                                        MB</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">100
                                        MB</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">50
                                        MB</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">25
                                        MB</a>

                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Fibertel
                                        Security</a>
                                </div>
                            </div>
                            <div className="col-centered w-12">
                                <div className="prefooter-title-box">
                                    <a 
                                        href="https://ccstore-stage-zepa.oracleoutsourcing.com/planesMoviles"
                                        _if="/planesMoviles">
                                        <h3 className="prefooter-title dsk-body-m-rbm no-margin no-padding"
                                            >Telefonia Movil Planes Moviles
                                        </h3>
                                    </a>
                                </div>
                                <div className="prefooter-detail">
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Black
                                        20 GB</a>
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Black
                                        12 GB</a>
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Plan
                                        8 GB</a>
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Plan
                                        5 GB</a>
                                    <a href="https://ccstore-stage-zepa.oracleoutsourcing.com/disney-plus#"
                                        className="prefooter-link dsk-body-rbm">Plan
                                        3 GB</a>
                                </div>
                            </div>
                            <div className="col-centered w-15">
                                <div className="prefooter-title-box">
                                    <h3 className="prefooter-title dsk-body-m-rbm no-margin no-padding">
                                        Busquedas Frecuentes</h3>
                                </div>
                                <div className="prefooter-detail">
                                    <p className="dsk-body-rbm"><a
                                            href="https://gestiones.cablevisionfibertel.com.ar/clientes/programacion">
                                            <p className="prefooter-link dsk-body-rbm">Programación</p>
                                        </a> <a
                                            href="https://www.cablevisionfibertel.com.ar/contratar-internet-tv">
                                            <p className="prefooter-link dsk-body-rbm">Armá tu combo</p>
                                        </a> <a
                                            href="https://ccstore-stage-zepa.oracleoutsourcing.com/internet">
                                            <p className="prefooter-link dsk-body-rbm">WIFI</p>
                                        </a> <a
                                            href="https://ccstore-stage-zepa.oracleoutsourcing.com/clasico/product/1">
                                            <p className="prefooter-link dsk-body-rbm">TV Cable</p>
                                        </a> <a
                                            href="https://www.personal.com.ar/clubpersonal/?utm_medium=Home&amp;utm_source=Cablevisionfibertel&amp;utm_campaign=Club_Personal&amp;utm_content=prefooter">
                                            <p className="prefooter-link dsk-body-rbm">Beneficios</p>
                                        </a> <a href="https://www.cablevisionfibertel.com.ar/hotsale">
                                            <p className="prefooter-link dsk-body-rbm">Hot Sale</p>
                                        </a></p>
                                </div>
                            </div>
                        </div>
                        <div className="row prefooter-data-mbl-row visible-xs">
                            <div className="prefooter-data-mbl-header">
                                <button className="prefooter-data-mbl-btn btn-arrows dsk-body-m-rbm"
                                    onClick="$(&#39;#prefooterOne&#39;).toggle(&#39;fast&#39;); $(&#39;i&#39;, this).toggleClass(&#39;fa-angle-up fa-angle-down&#39;);">
                                    <div styles="width: 95%">
                                        Combos</div>
                                    <i className="fal fa fa-angle-down prefooter-data-mbl-angle"></i>
                                </button>
                            </div>
                            <div className="collapse" id="prefooterOne"
                                styles="display: none;">
                                <div className="prefooter-data-mbl-body">
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">Móvil
                                        3 GB + Internet 50 MB + Flow Box</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">Móvil
                                        3 GB + Internet 50 MB + TV Clásico</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">Móvil
                                        5 GB + Internet 50 MB + Flow Box</p>
                                </div>
                            </div>
                            <div className="prefooter-data-mbl-header">
                                <button className="prefooter-data-mbl-btn btn-arrows dsk-body-m-rbm"
                                    onClick="$(&#39;#prefooterTwo&#39;).toggle(&#39;fast&#39;); $(&#39;i&#39;, this).toggleClass(&#39;fa-angle-up fa-angle-down&#39;);">
                                    <span styles="width: 95%">Productos</span>
                                    <i className="fal fa fa-angle-down prefooter-data-mbl-angle"></i>
                                </button>
                            </div>
                            <div className="collapse" id="prefooterTwo"
                                styles="display: none;">
                                <div className="prefooter-data-mbl-body">
                                    <h3 className="dsk-body-m-rbm prefooter-data-mbl-detail no-margin"
                                        >Televisión</h3>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Clásico</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Flow</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Flow Now</p>
                                    <h3 className="dsk-body-m-rbm prefooter-data-mbl-detail no-margin"
                                        >Adicionales</h3>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Pack Fútbol</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Pack HBO Max</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Pack FOX Premium</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Pack HOT</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Disney+</p>
                                    <h3 className="dsk-body-m-rbm prefooter-data-mbl-detail no-margin"
                                        >Internet</h3>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Fibertel Zone</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        1.000 MB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        300 MB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        100 MB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        50 MB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        25 MB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Fibertel Security</p>
                                    <h3 className="dsk-body-m-rbm prefooter-data-mbl-detail no-margin"
                                        >Telefonia Movil Planes Moviles</h3>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Black 20 GB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Black 12 GB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Plan 8 GB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Plan 5 GB</p>
                                    <p className="dsk-body-rbm prefooter-data-mbl-subdetail pointer">
                                        Plan 3 GB</p>
                                </div>
                            </div>
                            <div className="prefooter-data-mbl-header">
                                <button className="prefooter-data-mbl-btn btn-arrows dsk-body-m-rbm"
                                    onClick="$(&#39;#prefooterThree&#39;).toggle(&#39;fast&#39;); $(&#39;i&#39;, this).toggleClass(&#39;fa-angle-up fa-angle-down&#39;);">
                                    <h3 className="dsk-body-m-rbm no-margin" styles="width: 95%">
                                        Busquedas Frecuentes</h3>
                                    <i className="fal fa fa-angle-down prefooter-data-mbl-angle"></i>
                                </button>
                            </div>
                            <div className="collapse" id="prefooterThree"
                                styles="display: none;">
                                <div className="prefooter-data-mbl-body">
                                    <div className="prefooter-data-mbl-subdetail"
                                    ><a
                                            href="https://gestiones.cablevisionfibertel.com.ar/clientes/programacion">
                                            <p className="prefooter-link dsk-body-rbm">Programación</p>
                                        </a> <a
                                            href="https://www.cablevisionfibertel.com.ar/contratar-internet-tv">
                                            <p className="prefooter-link dsk-body-rbm">Armá tu combo</p>
                                        </a> <a
                                            href="https://ccstore-stage-zepa.oracleoutsourcing.com/internet">
                                            <p className="prefooter-link dsk-body-rbm">WIFI</p>
                                        </a> <a
                                            href="https://ccstore-stage-zepa.oracleoutsourcing.com/clasico/product/1">
                                            <p className="prefooter-link dsk-body-rbm">TV Cable</p>
                                        </a> <a
                                            href="https://www.personal.com.ar/clubpersonal/?utm_medium=Home&amp;utm_source=Cablevisionfibertel&amp;utm_campaign=Club_Personal&amp;utm_content=prefooter">
                                            <p className="prefooter-link dsk-body-rbm">Beneficios</p>
                                        </a> <a href="https://www.cablevisionfibertel.com.ar/hotsale">
                                            <p className="prefooter-link dsk-body-rbm">Hot Sale</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        
    );
  }
}
